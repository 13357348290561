.switcher {
    width: 32px;
    height: 11px;
    background-color: #808080;
    border-radius: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0;
    position: relative;
    transition: background-color 0.3s;
}

.switcher.on {
    background-color: #fff;
}

.switcher-thumb {
    width: 18px;
    height: 18px;
    background-color: #9528C6;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    transition: transform 0.3s;
}

.switcher.on .switcher-thumb {
    transform: translate(16px, -50%);
}
