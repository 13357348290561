.hidden {
  opacity: 0;
  transition: opacity 5.5s ease-out;
}

@keyframes fall {
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  80% {
    opacity: 1;
  }
  100% {
    transform: translateY(calc(100vh + 50px));
    opacity: 0;
  }
}

@keyframes fade-out {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.8;
  }
  75% {
    opacity: 0.2;
  }
  100% {
    opacity: 0;
  }
}

.hidden {
  opacity: 0;
}

.visible {
  opacity: 1;
  animation: fade-out 4.5s ease-out forwards;
}
