.main-boosters-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: hidden;
    height: 100%;
}


.main-boosters-wrapper h1 {
    font-size: 22px;
    font-weight: 700;
}

.main-boosters-boosters-list {
    display: flex;
    flex-direction: column;
    padding: 14px 18px;
    border-radius: 16px;
    background-color: rgba(217, 217, 217, 0.5);
    gap: 12px;
    overflow: auto;
}

.main-boosters-soon-wrapper {
    position: relative;
}

.main-boosters-soon-blur {
    font-size: 40px;
    position: absolute;
    font-family: "Archivo Black", sans-serif !important;
    font-weight: 400 !important;
    font-style: normal !important;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    background-color: rgba(238, 238, 238, 0.7) !important;
}

.boosters-toggle-wrapper {
    display: flex;
    align-items: center;
    flex-direction: row;
    gap: 12px;
    position: relative;
}

.boosters-header-item {
    position: relative;
    font-size: 16px;
}

.underline {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: #dff2d8;
}

.underline-youtube {
    position: absolute;
    bottom: -1px;
    left: 0;
    right: 0;
    height: 1px;
    background: #dff2d8;
}

