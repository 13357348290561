.page, .page-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    max-height: 100vh;
    background-position: center;
    background-size: cover;
}

.page-tasks {
  position: relative;
}


  .page-tasks .content .list .items {
    max-height: 100%;
    height: auto;
  }
  
  .page-tasks .content .list .item {
    position: relative;
    background-color: transparent;
    justify-content: flex-start;
  }
  
  .page-tasks .content .list .item.completed {
    pointer-events: none;
  }
  
  .page-tasks .content .list .item.completed::after {
    position: absolute;
    text-align: center;
    line-height: 90px;
    font-size: 20px;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.95);
    z-index: 1;
  }
  
  .page-tasks .flex-column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }
  
  .page-tasks .list .item {
    height: auto;
    margin: 0;
    padding: 10px;
  }
  
  .page-tasks .list .item img {
    width: 65px;
    height: 65px;
    border-radius: 10px;
    margin-right: 10px;
  }
  
  .page-tasks .list .item .username {
    margin-right: auto;
    font-size: 18px;
  }
  
  .page-tasks .list .item .balance {
    font-size: 14px;
    margin-right: 15px;
    color: #565656;
    font-weight: light;
  }
  
  .page-tasks .list .item .claim {
    font-size: 14px;
    color: #ffffff;
    background-color: #9747FF;
    font-weight: light;
    padding: 5px 10px;
    border-radius: 10px;
    border: 0;
    outline: none;
  }

  .task-collaboration-close {
    position: absolute;
    top: 5px;
    right: 20px;
    color: #000;
    font-size: 24px;
    cursor: pointer;
  }

  .task-collaboration-notification {
    z-index: 10000;
    position: absolute;
    width: 94%;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.5s;
    transition: all 0.3s ease-in-out;
    opacity: 0;
    visibility: hidden;

    &-open {
      opacity: 1;
      visibility: visible;
    }
  }

  .task-collaboration-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    flex-direction: column;
  }

  .task-list-header-text {
    font-size: 24px;
  }

  .task-list-header-btn {
    background-color: transparent;
    color: #ffffff;
    font-size: 16px;
    border-radius: 16px;
    padding: 5px 15px;
    border: 1px solid #fff;
  }

  .task-list-main-wrapper {
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    padding: 16px 18px;
    gap: 12px;
    height: 100%;
    overflow-y: auto;
  }

  .task-list-wrapper {
    display: flex;
    flex-direction: column;
    gap: 24px;
    height: 100%;
    overflow-y: auto;
  }

  .task-modal-desc {
    display: flex;
    gap: 16px;
  }

  .task-list-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  

  .task-content {
    justify-content: flex-start;
    gap: 32px;
  }

  .task-modal-reward {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: center;
    margin-bottom: 8px;
  }

  .task-modal-reward p  {
    font-size: 28px;
    color: #fff;
  }

  .task-modal-received {
    font-size: 20px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
  }

  .new-task-group-wrapper {
      display: flex;
      flex-direction: column;
      gap: 12px;
  }

  .new-task-group-wrapper-label {
    font-size: 18px;
  }

  .task-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow: scroll;
    height: 100%;
  }

  .task-toggle-label {
    font-size: 15px;
  }

  .task-list-empty {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .empty-list-description {
    color: #fff;
    align-items: center;
    text-align:center;
  }

  .task-root-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    overflow-y: hidden;
    height: 100%;
  }

  .custom-font-balance-black h2 {
    color: #000 !important;
  }

  .custom-font-balance-black .page-claim-title {
    color: #000 !important;
  }

  .custom-font-balance-black span {
    color: #000 !important;
  }

  .custom-font-balance, .custom-font-balance span {
    color: #fff !important;
  }

  .c-rune-icon-task {
    bottom: 0;
  }

  .custom-font-balance-plain-text {
    color: #494949!important;
  }


  .custom-font-balance .purple-color {
    color: #8E53FF!important;
  }