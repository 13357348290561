.wai-additional-info {
    display: flex;
    justify-content: space-between;
}

.wai-additional-info-league {
    display: flex;
    flex-direction: column !important;
    & p:first-child {
        align-self: baseline;
    }
}


.wai-additional-info-root {
    display: flex;
    gap: 4px;
    flex-direction: column;
    justify-content: center;
}

.wai-additional-info-text {
    color: #BCCAFF;
}

.wai-additional-info-value {
    color: #BCCAFF;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
}

