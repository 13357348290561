.wai-upgrade-item {
    filter: blur(2%);
    border-radius: 50%;
    border: 2px solid #8254BD;
    box-shadow: 0px 0px 10px #8254BD, 0px 0px 30px #C333E7;
    display: flex;
    align-items: center;
    justify-content: center;
    backdrop-filter: blur(1.7px);
    position: absolute;
    height: 80%;
    width: auto;
    aspect-ratio: 1 / 1;
}

.wai-upgrade-item  {
    position: absolute;
    top: 50%;
    left: 50%;
}

.wai-upgrade-level {
    border: 1px solid #B192D9;
    background: linear-gradient(3deg, #C395FF, #9432AC);
    border-radius: 50%;
    padding: 10px;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
}

.wai-upgrade-market-analysis-level-size {
    font-size: 12px;
    width: 40px;
    height: 40px;
}



.wai-upgrade-notification {
    background-color: #F25A5A;
    border-radius: 50%;
    width: 15px;
    height: 15px;
    right: 14px;
    position: absolute;
    z-index: 200;
    top: 15px;
    right: 1px;
}

.wai-upgrade-body-item {
    width: 50%;
    height: 50%;
    box-sizing: border-box;
    position: relative;
}

