.page-2 {
    text-align: center;
  }
  
.page-2 .content {
    align-items: center;
  }
  

  .page-2 h1 {
    color: #000000;
    font-size: 40px;
    margin-bottom: 0.45rem;
  }
  
  .page-2 .bottom-link {
    color: #868686;
    font-size: 18px;
    font-weight: light;
    text-decoration: none;
    margin-bottom: 40px;
  }
  
  .page-2 .main-button {
    background-color: #EEEEEE;
  }

  .form-placeholder {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 76%;
    width: 100%;
    margin-top: 20px;
    padding: 0 25px 0px 25px;
    background-position: center;
    background-size: contain;
    gap: 8px;
  }
  
  .form-placeholder .title {
    color: #171717;
    font-size: 16px!important;
    text-align: left;
    width: 100%;
  }
  
  .form-placeholder .title.big {
    text-align: center;
    font-size: 24px !important;
    margin-top: 10px;
    margin-bottom: 20px;
  }

  .form-placeholder .subtitle {
    margin-top: 10px;
    color: #52544F;
    font-size: 12px;
    text-align: left;
    width: 100%;
  }
  
  .form-placeholder .radio-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin-top: 20px;
  }



.stakes {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    height: 100%;
    // margin-top: 20px;
  }
  
  .stakes .staking-box {
    max-height: 100px;
    height: 90px;
  }
  
  .stakes .staking-box .col .item:last-child {
    margin-top: 5px;
  }
  
  .stakes .staking-box .col {
    padding: 0 10px;
  }
  
  .stakes .staking-box .col .item .value {
    margin-top: 0;
    font-size: 16px;
  }
  
  .stakes .staking-box.new {
    align-items: center;
    justify-content: center;
  }
  
.staking-box {
  width: 100%;
  padding: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background-color: #E3E3E3;
  border-radius: 27px;
  box-shadow: inset 0 2px rgba(0, 0, 0, 0.1);
}

.staking-box .col {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 50%;
  padding: 10px;
}

.staking-box .col:first-child {
  border-right: 1px solid #ffffff;
}

.staking-box .col .item {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
}

.staking-box .col .item:last-child {
  margin-top: 20px;
}

.staking-box .col .item .title {
  color: #52544F;
  font-size: 10px;
  text-align: left;
  width: 100%;
}

.staking-box .col .item .value {
  margin-top: 10px;
  color: #171717;
  font-size: 16px;
  text-align: left;
  width: 100%;
}

.page-dashboard .content {
  height: 100%;
  padding: 120px 20px 20px 20px;
  background-position: center;
  background-size: contain;
  background-repeat: no-repeat;
}

.page-dashboard .content h1 {
  font-size: 44px;
}
.dashboard-content {
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.staking-box-item {
  max-width: 80%;
  position: relative;
}

.claim-box {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(151, 71, 255, 0.5);
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 27px; 
}

.claim-box-claim {
  font-size: 35px;
}

.staking-heading {
margin: 0 auto;
}

.dashboard-value {
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 20px;
}

.gap {
  gap: 7px
}

.staking-main-wrapper {
  position: relative;
}

.staking-wrapper-completed {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;
    height: 290px;
    overflow: auto;
}

.stacking-empty-list {
  justify-content: center;
  align-items: center;
  font-size: 20px;
}

.staking-box-closed-stacking {
  display: flex;
  align-items: center;
  padding: 0 24px;
  gap: 6px;
  justify-content: space-between;
  background: #E3E3E3;
}

.staking-box-closed-stacking-label {
  font-size: 20px;
}

.staking-box-closed-stacking-value {
  font-size: 42px;
}

.staking-box-closed-stacking-value-purple {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(to right, #8254BD, #C333E7);
}

.staking-box-closed-stacking-value-green {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to bottom, #5BC951, #00950F);
}