:root {
  --header-color: #dff2d8;
  --tg-theme-bg-color: #fff;
  --tg-theme-text-color: #0a0a0a;
  --tg-theme-hint-color: #929292;
  --tg-theme-link-color: #207ae4;
  --tg-theme-button-color: #5bc8fb;
  --tg-theme-button-text-color: #fffeec;
  --tg-theme-secondary-bg-color: #f3f2f9;

  --default-font: "Archivo", -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji",
    "Segoe UI Symbol";
}

#root {
  z-index: 1;
}

.ab {
  font-family: "Archivo Black", sans-serif !important;
  font-weight: 400 !important;
  font-style: normal !important;
}

a {
  text-decoration: none;
}

body {
  text-decoration: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-touch-callout: none;
  background-color: #000;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
  padding: 0;
  margin: 0;
}

.main-heading {
  color: #dff2d8;
  font-size: 40px;
}

.main-wrapper {
  padding: 16px 24px;
  height: 100%;
}

.plain-text {
  color: #52544f;
}

.default-color {
  color: #000;
}

.font-size-18 {
  font-size: 18px;
}

.font-size-16 {
  font-size: 16px;
}

.font-size-15 {
  font-size: 15px;
}

.font-size-12 {
  font-size: 12px;
}

#root {
  position: relative;
  width: 100%;
  height: 100%;
}

body {
  background-color: #000;
  padding: 0;
  margin: 0;
  max-height: 100vh;
  overflow: hidden;
  font-family: var(--default-font);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.contentWrapper {
  background: var(--tg-theme-bg-color);
  color: var(--tg-theme-text-color) !important;
  border-radius: 10px;
  margin: 5px 0;
  padding: 20px;
  box-sizing: border-box;
}

.ant-input,
.ant-select,
.ant-select-item {
  background-color: unset !important;
}

/* w */

.page .content {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.custom-radio-button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 30%;
}

.custom-radio-button img {
  box-sizing: border-box;
  width: 100%;
}
.custom-radio-button::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 2px solid #464c72;
  border-radius: 20px;
  width: 100%;
  height: 91%;
  opacity: 0;
  transition: 0.1s;
}

.custom-radio-button.selected::after {
  opacity: 1;
}
.button-back {
  flex-grow: 1;
  margin-right: 10px;
}

.slider-value {
  margin-top: 20px;
  margin-bottom: 10px;
}

.slider-max {
  color: #171717;
  font-size: 14px;
}

.slider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  cursor: pointer;
  outline: none;
  border-radius: 15px;
  height: 20px;
  height: 6px;
  background: #ccc;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #464c72;
  cursor: pointer;
}

/* Thumb: Firefox */
.slider::-moz-range-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  background: #464c72;
  cursor: pointer;
}

.slider::-webkit-slider-thumb:hover {
  box-shadow: 0 0 0 10px rgba(104, 101, 149, 0.1);
}
.slider:active::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(104, 101, 149, 0.2);
}

.slider:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 13px rgba(104, 101, 149, 0.2);
}

.slider::-moz-range-thumb:hover {
  box-shadow: 0 0 0 10px rgba(104, 101, 149, 0.1);
}
.slider:active::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(104, 101, 149, 0.2);
}
.slider:focus::-moz-range-thumb {
  box-shadow: 0 0 0 13px rgba(104, 101, 149, 0.2);
}

.purple {
  color: #9747ff !important;
}

.btn-disabled {
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
}

::selection {
  background: transparent;
}
::-moz-selection {
  background: transparent;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.toast-message {
  background: darkblue;
  color: #fff;
  font-size: 20px;
  width: 34vw;
  padding: 30px 20px;
}

.Toastify__toast {
  border-radius: 16px;
  padding: 8px 10px;
  display: flex;
  z-index: 1000;
}

.Toastify__toast-body {
  font-size: 15px;
}

.Toastify__progress-bar {
  background: #A344D2 !important;
}

.green-color {
  color: #dff2d8 !important;
}

.main-color {
  color: #dff2d8 !important;
}

.purple-color {
  color: #9747ff !important;
}

.gold-color {
  color: #f7ce45 !important;
}

.blur-bg {
  background-color: rgba(238, 238, 238, 0.2) !important;
}

.blur-bg-strong {
  background-color: rgba(238, 238, 238, 0.3) !important;
}

.common-color {
  color: #bebebe !important;
}

.new-bottom {
  max-width: 80% !important;
  background-color: transparent !important;
  border: 3px solid #dff2d8 !important;
  color: #dff2d8 !important;
}
.blur {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000001;
  height: 100vh;
  backdrop-filter: blur(4px);
}

.blur-max {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 10000001;
  height: 100vh;
  backdrop-filter: blur(22px);
  background-color: rgba(0, 0, 0, 0.5);
}

.bebebe {
  background-color: rgba(217, 217, 217, 0.15) !important;
}

.blockchain-content-name {
  word-break: break-word !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.galaxy-color {
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #FFFFFF 0%, #E0EFFF 25%, #D6D6E7 50%, #C3C3D5 75%, #E0D8FF 90%, #FFFFFF 100%);
}

@media (max-height: 663px) {
  .dashboard-header-item {
    color: #fff !important;
  }

  .dashboard-underline2 {
    background:  #fff !important;
  }

  /* page - claim */
  .page-claim-leaderboard {
    font-size: 14px!important;
  }

  .balance-coin {
    width: 30px!important;
  }

  .claim-button {
    width: 220px!important;
    height: 220px!important;
  }

  .page-claim .content .box span {
    font-size: 35px !important;
  }

  .claim-button-wrapper {
    width: auto !important;
    height: auto !important;
  }

  .page-claim .content .bottom-nav {
    padding: 0px !important;
  }

  .page-claim .content {
    gap: 16px !important;
  }

  .page-claim .content .bottom-nav a h3 {
    font-size: 24px !important;
  }

  .main-wrapper {
    padding: 36px 24px 20px!important;
  }

  /* page - mates */
  .main-heading {
    font-size: 35px !important;
  }

  .font-size-15 {
    font-size: 14px !important;
  }

  .mates-content-wrapper {
    gap: 16px !important;
  }

  .mates-content-desc-level-wrapper {
    width: 60px !important;
    height: 60px !important;
  }

  .mates-content-desc-level {
    font-size: 24px !important;
  }

  .page-1 .main-button {
    font-size: 20px !important;
    padding: 8px 0 !important;
  }

  /* TASKS */

  .app-modal-content-button {
    padding: 8px !important;
  }

  .task-modal-icon img {
    width: 68px!important;
    height: 68px!important;
  }

  /* DASHBOARD */

  .dashboard-content {
    background-image: none !important;
  }

  /* STAKING */
  .staking-main-img {
    height: 294px !important;
  }

  .form-placeholder {
    background-image: none !important;
  }

  .form-placeholder .title, .form-placeholder .subtitle{
    color: #fff!important;
  }

  /* change-blockchain */
  .blockchain-content-select .css-1fdsijx-ValueContainer {
    padding: 8px !important;
  }

  .blockchain-content-wrapper {
    gap: 16px !important;
    padding: 20px 30px !important;
  }

  .blockchain-content-name {
    font-size: 28px !important;
  }

  .blockchain-content-name {
    gap: 16px !important;
    word-wrap: break-word !important;
  }


  .blockchain-content-name {
    word-break: break-word !important;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }

  .blockchain-content-select-wrapper-main {
    gap: 12px !important;
  }
}



@media (max-height: 515px) {
  .claim-button {
    width: 155px!important;
    height: 155px!important;
  }


  .page-1 .main-button {
    font-size: 10px !important;
    padding: 4px 0 !important;
  }

  .font-size-15 {
    font-size: 10px !important;
  }

  .mates-description {
    gap: 0 !important;
  }

  .mates-invite-button {
    font-size: 12px !important;
    padding: 4px 8px !important;
  }
  .list-title {
    height: 15px !important;
    font-size: 12px !important;
  }

  .list-item-short-username {
    width: 45px !important;
    height: 45px !important;
  }

  .mates-invite-root {
    padding: 4px !important;
  }

  .mates-content-wrapper {
    gap: 8px !important;
  }

  .task-item-avatar {

    width: 68px !important;
    height: 68px !important;
  }

  .main-boosters-avatar img {
    width: 50px !important;
    height: 50px !important;
  }

  .main-boosters-avatar {
    font-size: 34px !important;
  }

  .blockchain-wrapper {
    gap: 4px !important;
  }

  .main-wrapper {
    padding: 14px 24px 20px !important;
  }
}


.white-color {
  color: #fff !important;
}

.red-color {
  color: red !important;
}

.collab-color {
  color: #00F0FF !important;
}

.go816652413 {
  position: absolute;
  left: -225.5px;
  top: -169px;
}

.gold-color {
  color: #AFB4A8 !important;
}

.carousel-button.prev {
  left: -8px!important;
}

.carousel-button.next {
  right: -8px!important;
}