@font-face {
  font-family: 'Ploni Ultralight'; /* Имя шрифта */
  src: url('../../../../assets/Ploni-Ultralight-AAA.ttf') format('truetype'); /* Путь к файлу */
  font-weight: normal;
  font-style: normal;
}

@keyframes float {
    0% {
      transform: translateY(0);
    }
    50% {
      transform: translateY(-10px);
    }
    100% {
      transform: translateY(0);
    }
  }
  
  .float-animation {
    animation: float 4s ease-in-out infinite;
}

.loader-text {
  font-size: 20px;
  text-transform: uppercase;
  font-family: 'Ploni Ultralight', sans-serif;
}