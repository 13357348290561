.list-item-root {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    padding: 8px;
    background-color: rgba(238, 238, 238, 0.6);
    border-radius: 16px;
}

.list-item--username-stack {
    align-items: center;
    gap: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: grid;
    grid-template-columns: 60px 1fr;
}

.list-item-short-username{
    width: 60px;
    height: 60px;
    border-radius: 16px;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
        border-radius: 14px;
    }
}

.list-item-short-username p, .list-item-username {
    font-size: 18px;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.list-item-balance {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;
}

.list-item-balance p {
    font-size: 15px;
    color: #565656;
}

.list-item-username-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 4px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}