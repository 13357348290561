.okx-mobile-input {
    width: 100%;
    border: 0px;
    border-radius: 18px;
    padding: 10px;
    background-color: rgba(217, 217, 217, 0.5);
    outline: none;
    box-shadow: none;
    height: 100%;
    
    &::placeholder {
        color: #fff;
        opacity: 1;
   }
}
.okx-content-wrapper {
    position: relative;
    background-color: #000;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.okx-title-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
}
.okx-title {
    color: #fff;
    font-size: 24px;
}
.okx-subtitle {
    color: #fff;
    font-size: 16px;
    text-align: center;
}
.okx-actions {
    display: flex;
    flex-direction: column;
    gap: 30px;
}
.okx-input-root {
    display: flex;
    gap: 6px;
    justify-content: space-between;
    height: 45px;

    & input[type='text'] {
        color: #fff;
   }
}
.okx-input-wrapper {
    width: calc(100% - 127px);
    display: flex;
    flex-direction: column;
    gap: 4px;
}
.okx-redeem-button, .okx-download-button {
    background-color: #9747ff;
    border-radius: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 11px;

    &-time {
        border: 3px solid #A0A0A0;
        background-color: transparent;        
    }
}
.okx-download-button {
    padding: 15px;
}
.okx-redeem-text,
.okx-download-text {
    font-size: 20px;
    color: #fff;
}
.okx-redeem-text {
    width: 93px;
    text-align: center;
}
.okx-redeem-text-time {
    color: #A0A0A0;
    width: 93px;
}
.okx-validation-label {
    color: red;
    font-size: 14px;
    height: 16px;
    margin-left: 10px;
    visibility: hidden;
    margin-top: 4px;

    &-visible {
        visibility: visible;
    }
}
.okx-redeem-button-empty,
.okx-redeem-button,
.okx-redeem-button-time {
    width: 121.37px;
    height: 23px;
}
.okx-redeem-button-time {
    height: 45px;
}