.wai-content-root {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.wai-content-item-header {
    padding: 24px 24px 0;
}

.wai-content-item-body {
    flex: 1;
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    height: 100%;
}

.wai-content-item-footer {
    padding: 24px;
}

.wai-content-item-header,
.wai-content-item-body, 
.wai-content-item-footer {
    z-index: 200;
}