.mates-list-root {
    display: flex;
    width: 100%;
    gap: 32px;
    overflow: hidden;
}

.mates-list {
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    gap: 12px;
}