.wai-action-button-root {
    // position: absolute;
    bottom: 0px;
    width: 100%;
    display: flex;
    justify-content: center;
}

.wai-action-wrapper {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 20px;

}

.wai-action-button {
    border: 1px solid #772ADB;
    border-radius: 38px;
    padding: 24px;
    width: 200px;
}

.wai-action-text {
  color: #BCCAFF;
}

.wai-action-text-timer {
  color: #772ADB;
  border: 3px solid #772ADB;
  background: linear-gradient(3deg, rgba(130, 84, 189, 0.4), rgba(35, 35, 231, 0.011) 134%);
  padding: 14px 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  font-size: 26px;
  box-shadow: 0 0 45px rgba(255, 255, 255, 0.7);
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in forwards;
}

.wai-action-text-timer-claim  {
  color: #A159FF;
  border: 3px solid #A159FF;
  background: linear-gradient(3deg, rgba(130, 84, 189, 0), rgba(35, 35, 231, 0.011) 134%);
  padding: 14px 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  font-size: 26px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in forwards;
  box-shadow: 0px 0px 10px #8254BD, 0px 0px 30px #C333E7;
}

.wai-action-text-timer-claim-pay-pass  {
  color: #F7CE45;
  border: 3px solid #F7CE45;
  background: linear-gradient(3deg, rgba(130, 84, 189, 0), rgba(35, 35, 231, 0.011) 134%);
  padding: 14px 33px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 36px;
  font-size: 26px;
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 0.5s ease-in forwards;
  box-shadow: 0px 0px 10px #F7CE45, 0px 0px 30px #F7CE45;
}

.wai-action-text-timer-claim-multiplier {
  border: 3px solid #F7CE45;
  box-shadow: 0px 0px 10px #F7CE45, 0px 0px 30px #ECECEC;
  color: transparent;
  -webkit-background-clip: text;
  background-clip: text;
  background-image: linear-gradient(to right, #F7CE45 0%,  #ECECEC 100%);

}

@keyframes fadeInUp {
  to {
      opacity: 1; 
      transform: translateY(0);
  }
}

.trade-button {
  box-shadow: 0 0 74px rgba(255, 255, 255, 0.9);
}

.action-btn-wrapper-pay-pass-width {
  width: 201px!important;
}

.wai-action-text-trade {
  padding: 0px 35px;
}

.hide-margin-bottom {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.action-btn-wrapper {
  width: 220px !important;
  height: 64px;
}
