.stroke-font {
    color: #000;
}

.selected {
    font-size: 15px;
}

.underline-bg {
    background-color: #000!important;
    background: #000!important;
}

.task-toggle-label-white-space {
    white-space: nowrap;
}