.league-list-content-root {
    text-align: center;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    height: 100%;
}

.league-list-content-title {
    font-size: 33px;
}

.league-list-content-header {
    display: flex;
    flex-direction: column;
    gap: 16px;
    width: 90%;
}

.league-list-content-divider {
    margin: 0 auto;
    width: 90%;
    height: 1px;
    background-color: #7A7A7A;
}

.league-list-content-league {
    margin: 0 auto;
    border-radius: 50%;
    border: 2px solid #8254BD;
    box-shadow: 0px 0px 10px #8254BD, 0px 0px 30px #C333E7;
    width: 90px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(135deg, rgba(130, 84, 189, 0.5), rgba(195, 51, 231, 0.3));
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 150px;
    height: 150px;
}

.league-list-content-league-name {
    color: #BCCAFF;
    font-size: 24px;
}

.react-multiple-carousel__arrow {
    top: 227px!important;
    position: fixed !important;
}

.react-multi-carousel-list {
        overflow: unset;
        position: relative;
        height: 100%;
        width: 100vw;
        align-items: unset!important;
}

.pointer-events-none {
    pointer-events: none;
}


html, body {
    height: 100%;
    margin: 0;
  }
  
  body {
    display: flex;
    flex-direction: column;
  }
  
  .table-container {
    flex: 1 1;
    overflow-y: auto;
    width: 100%;
    border-radius: 12px;
    padding: 18px;
    display: flex;
    flex-direction: column;
    gap: 4px;
    padding: 0;
    height: 100%;
  }
  
  .table {
    display: flex;
    flex-direction: column;
    height: 100%;
  }
  
  .league-table-item {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    border-bottom: 1px solid #5F5F5F;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  
  .league-table-item-start {
    display: flex;
    align-items: center;
    gap: 20px;
    font-size: 25px;
    height: 80px;
  }
  
  .league-table-item-end {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    width: 84px;
  }
  
  .gray-color {
    color: #9A9595;
  }

  .force-left-0 {
    left: 0px !important;
  }

  .gold-color {
    color: gold!important;
  }

  .empty-div {
    width: 20px;
  }

  .table-root {
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 90%;
    flex: 1 1;
    padding-left: 16px;
    padding-right: 16px;
    overflow: auto;
  }

  .table-header {
    display: flex;
    justify-content: space-between;
  }

  .table-container-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #fff;
    gap: 24px;
    padding-left: 18px;
    padding-right: 18px;
    padding-top: 6px;
    padding-bottom: 6px;
  }

  .table-container-item-selected {
    border-top: 2px solid #8254BD;
    border-bottom: 2px solid #8254BD;
    background: linear-gradient(to right, #3E0090 0%, #12002A 100%);
  }

  .table-container-item-start {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 24px;
    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .table-container-item-position {
    font-size: 18px;
    width: 24.38px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .table-container-item-name {
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; 
  }

  .table-container-item-reward {
    display: flex;
    font-size: 20px;
    align-items: center;
    gap: 4px;
    color: #1AC9FF;
  }

  .wai-toggle {
    color: #FFFFFF!important;
    font-size: 16px;
  }

  .wai-toggle-disabled {
    color: #BABABA !important;
  }

  .empty-league {
    color: #FFFFFF!important;
    font-size: 16px;
  }

  .table-container-item-place {
    width: 38px;
    height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 9px;
    background-color: #434345;
  }

  .user-place {
    display: flex;
    justify-content: flex-start;
    color: #FFFFFF!important;
    font-size: 16px;
    margin: 0 auto;
  }

  .wrapper-league {
    flex: 1 1;
    overflow-y: hidden;
    width: 100%;
    display: flex;
    position: relative;
    border: 2px solid #8254BD;
    border-radius: 12px;
  }
