.wai-modal-upgrade-root {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.wai-upgrade-values {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}