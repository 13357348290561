.wallet-content-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 27px;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    overflow: hidden;
    justify-content: space-between;
};

.wallet-content-balance {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.wallet-content-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 16px;
}

.page-wallet-btn {
    background-color: transparent!important;
    border: 3px solid #9747FF!important;
    border-radius: 36px;
    color: #9747FF!important;
    margin: 0!important;
    max-width: unset!important;
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin: 24px!important;
}

.c-rune-icon {
    width: 60px;
    position: absolute;
    right: 50px;
    z-index: 1;
}

.wallet-wrapper {
    padding: 12px;
}


.wallet-connect-btn {
    display: flex;
    width: 100%  !important; 
    color: 9747FF;
    align-items: center;
    text-align: center;
    justify-content: center;

    button {
        background-color: transparent !important;
        box-shadow: unset !important;

        div {
            color: #9747FF !important;
            font-size: 20px;
            height: auto;
        }

        svg {
            display: none;
        }
    }
}

.wallet-actions-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-top: 18px;
    width: 100%;
    position: relative;
    color: #A159FF;
    border: 3px solid #A159FF;
    background: linear-gradient(3deg, rgba(130, 84, 189, 0), rgba(35, 35, 231, 0.011) 134%);
    padding: 8px 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    font-size: 26px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease-in forwards;
    box-shadow: 0px 0px 10px #8254BD, 0px 0px 22px #C333E7;
    width: 90% !important;
    margin: 17px!important;
}

.wallet-content-wrapper-root {
    overflow-y: auto;
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.self-bottom {
    justify-content: end;
}

.waller-connect-btn-root {
    padding: 11.5px 0 !important;
    width: 100% !important;
}

.ellipsis {
    max-width: 275px;
    width: 100%;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.actions-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.main-wrapper-actions {
    display: flex;
    justify-content: center;
    width: 100% !important;
}

