.page-claim-header {
  width: 100%;
  display: flex;
  padding: 24px 24px 0 24px;
  position: absolute;
  align-items: center;
  flex-direction: column;
  gap: 8px;
}

.page-claim-header-root {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.page-claim-icons {
  display: flex;
  gap: 16px;
  align-items: center;
}

.page-claim-main-wrapper {
  padding-top: 60px;
}

.page-claim-content-wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.page-claim {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    position: relative;
  }
  
  .page-claim .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
  }


  .page-claim .content .box {
    text-align: center;
  }
  
  .page-claim .content .box span {
    color: #52544F;
    font-size: 14px;
    font-weight: light;
  }
  
  .page-claim .content h1 {
    margin-bottom: 0;
    font-size: 40px;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .box-balance {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 50%;
    justify-content: center;
    align-items: center;

    & span {
      margin-bottom: 0;
      color: #DFF2D8!important;
      font-size: 43px!important;
      text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }

  .box-claim-wrapper {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: space-around;
    gap: 8px;
    width: 100%;
  }

  .balance {
    display: flex;
    gap: 8px;
    align-items: center;
    font-size: 40px;
  }
  
  .page-claim .content .claim {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 0;


  }

  .page-claim-leaderboard {
    border-radius: 16px;
    border: 2px solid #CCA4FF;
    color: #CCA4FF;
    padding: 6px 13px;
    cursor: pointer;
    max-width: 160px;
  }

  .main-button {
    display: block;
    width: 260px;
    height: 90px;
    margin-top: 10px;
    background-color: #ffffff;
    color: #000000;
    font-size: 50px;
    border: 1px solid #464C72;
    border-radius: 30px;
    box-shadow: 5px 10px 10px rgba(70, 76, 114, 0.5);
  }
  
  .main-button:active {
    background-color: #464C72;
    color: #ffffff;
    transition: 0.5s;
    box-shadow: 0px 0px 0px rgba(0, 0, 0, 0.5);
  }
  
  .page-claim .main-button {
    margin-top: -50px;
  }
  
  .page-claim .content .bottom-nav {
    display: flex;
    justify-content: space-around;
    align-items: center;
    border: 1px solid #464C72;
    border-radius: 20px;
    padding: 8px;
  }
  
  .page-claim .content .bottom-nav a {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    margin: 5px 0;
    text-align: center;
    text-decoration: none;
    color: #565656;
    font-size: 12px;
    font-weight: light;
    border-right: 1px solid #D9E9C3;
    width: 100%;
    align-items: center;
    gap: 2px;
  }
  
  .page-claim .content .bottom-nav a:last-child {
    border-right: none;
  }
  
  .page-claim .content .bottom-nav a h3 {
    margin: 0;
    font-size: 30px;
  }

  .claim-button-root {
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
  }

  .claim-button-root::selection {
    background: transparent;
  }

  .claim-button-wrapper {
    position: relative;
    width: 310px;
    height: 310px;
    display: flex;
    justify-items: center;
    justify-content: center;
    border-radius: 50%;
  }

  .claim-button-wrapper::selection {
    background: transparent;
  }

  .claim-button-progressbar {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: scaleX(-1) rotate(-0deg);
    pointer-events: none;
  }

  .claim-button-progressbar::selection {
    background: transparent;
  }

  .claim-button {
    width: 290px;
    height: 290px;
    text-decoration: none;
    background: transparent;
    border: 0px solid transparent;
    overflow: hidden;
    padding: 4px;
  }

  .claim-button::selection {
    background: transparent;
  } 

  .claim-button img {
    width: 100%;
    margin: 0 auto;
  }

  .claim-button img::selection {
    background: transparent;
  } 

  .claim-button.disabled {
    opacity: 0.5;
    pointer-events: none;
  }

  @keyframes flyAndFade {
    0% {
      opacity: 1;
      transform: translateY(0);
    }
    100% {
      opacity: 0;
      transform: translateY(-120px) scale(2);
    }
  }

  .fly-and-fade-basic-color {
    color: #DFF2D8;
  }


  .fly-and-fade-gold-color {
    color: #F7CE45;
  }
  
  .flyAndFade {
    animation: flyAndFade 1s forwards;
    pointer-events: none;
  }

  .claim-charger-count {
    display: flex;
    flex-direction: row;
  }

  .claim-charger-count-text {
    font-size: 14px!important;
    font-weight: 400px;
  }

  .claim-charger {
    display: flex;
    flex-direction: row;
    gap: 4px;
  }

  .disable-dbl-tap-zoom {
    touch-action: manipulation;
  }

  .claim-balance,
  .page-claim-leaderboard-link,
  .claim-charger,
  .bottom-nav,
  .page-claim-header,
  .page-claim-title {
    z-index: 10;
  }

  .page-claim-clans {
    border: 2px solid #B4B4B4;
    color: #B4B4B4;
    filter: blur(1.3px);
  }

  .page-claim-actions {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  .page-claim-title {
    color: #AFB4A8;
  }


  .claim-progress {
    margin: 0 auto;
    width: 70%!important;
  }


  .active-task {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    left: 45px;
    top: -3px;
    color: #fff;
    font-size: 13px;
    animation: blink 1s linear infinite;
  }

  .active-task-boosts {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    width: 15px;
    height: 15px;
    background-color: red;
    border-radius: 50%;
    left: 45px;
    top: -3px;
    color: #fff;
    font-size: 13px;
    animation: blink 1s linear infinite;
  }

  @keyframes blink {
    0% {opacity: 0.3;}
    50%{opacity: 1;}
    100%{opacity: 0.3;}
  }

  .wai-button {
    animation: blink-btn 2s linear infinite;
  }

  @keyframes blink-btn {
    0% {opacity: 0.3;}
    50%{opacity: 2;}
    100%{opacity: 0.3;}
  }
  
 
  @keyframes matrixGlow {
    0% {
      opacity: 0.9;
      filter: drop-shadow(0 0 0.5px #00ff00) drop-shadow(0 0 1px #00ff00);
    }
    25% {
      opacity: 0.91;
      filter: drop-shadow(0 0 0.75px #00ff00) drop-shadow(0 0 1.5px #00ff00);
    }
    50% {
      opacity: 0.92;
      filter: drop-shadow(0 0 1px #00ff00) drop-shadow(0 0 2px #00ff00);
    }
    75% {
      opacity: 0.91;
      filter: drop-shadow(0 0 0.75px #00ff00) drop-shadow(0 0 1.5px #00ff00);
    }
    100% {
      opacity: 0.9;
      filter: drop-shadow(0 0 0.5px #00ff00) drop-shadow(0 0 1px #00ff00);
    }
  }
  
  
  