.app-pay-pass-error-notification-root {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px;
}

.app-pay-pass-error-notification-wrapper {
  padding: 28px 12px 12px 12px!important;
  background-attachment: #fff;
  height: 169px;
  background: #fff;
  border-radius: 21px;
}

.close-modal-error-notification {
  color: #000!important;
}

.error-notification-info-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.error-notification-heading {
  color: #000;
  font-size: 20px;
}

.error-notification-sub-heading {
  color: #000;
  font-size: 17px;
  display: flex;
  gap: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.error-notification-sub-heading-support {
  color: #9747FF;
  cursor: pointer;
}

.error-notification-heading-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: center;
}

.error-notification-action {
  width: 166px;
  padding: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 17px;
  border-radius: 17px;
  background-color: #9747FF;
  color: #fff;
}


