.history-reward-color-battery,
.history-reward-color-wcoin,
.history-reward-color-dice ,
.history-reward-color-wai,
.history-reward-color-key,
.history-reward-color-pay-pass {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
}


.history-reward-color-battery {
    background-image: linear-gradient(to right, #04BC0B, #8ADD97);
}

.history-reward-color-wcoin {
    background-image: linear-gradient(to right, #8254BD, #C333E7);
}

.history-reward-color-dice {
    background-image: linear-gradient(to right, #E92626, #FCA2A2);
}

.history-reward-color-wai {
    background-image: linear-gradient(to right, #E0EFFF, #C3C3D5);
}

.history-reward-color-key {
    background-image: linear-gradient(to right, #8254BD, #C333E7);
}

.history-reward-color-pay-pass {
    background-image: linear-gradient(to right, #8254BD, #C333E7);
}








