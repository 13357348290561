.main-boosters-root {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
}

.main-boosters-left {
    display: flex;
    flex-direction: row;
    gap: 16px;
}

.main-boosters-description-wrapper {
    display: flex;
    flex-direction: column;
    gap: 12px;
    justify-content: center;
}

.main-boosters-description {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 20px;
    gap: 4px;
}

.main-boosters-price {
    font-size: 15px;
    font-weight: 500;
}

.main-boosters-dot {
    font-size: 14px;
    font-weight: 500;
}

.main-boosters-level {
    font-size: 14px;
    font-weight: 500;
    color: #6E6E6E;
}


.main-boosters-description-wrapper> p {
    font-size: 16px;
    font-weight: 500;
}

.main-boosters-avatar {
    display: flex;
    width: 23vw;
    height: 11vh;
    border-radius: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px;
    gap: 16px;
    font-size: 54px;
    max-width: 99px;
    max-height: 100px;
}

.main-boosters-right {
    display: flex;
    justify-content: center;
    align-items: center;
}

.disabled-booster-btn {
    filter: grayscale(1) brightness(0.8) contrast(1.2) sepia(0.4);
    pointer-events: none;
}