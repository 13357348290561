.slides-desc {
    color: #fff;
    font-size: 16px;
}

.slides-wrapper {
    display: flex;
    flex-direction: column;
    gap: 44px;
    align-items: center;
    justify-content: center;
    width: calc(100% - 138px);
    margin-top: 44px;

}

.slides2-img {
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 16px;
}

.slides3-desc-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.slides-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 24px;
}

.slides3-desc-wrapper-root {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.carousel-template-page-children-slide3 {
    justify-content: center;
    height: 100%;
    gap: 24px;
    display: flex;
}

.slides3-desc-wrapper-root-wrapper {
    display: flex;
    flex-direction: column;
    gap: 16px
}

.slides-desc-title {
    font-size: 20px;
}

.slides-text-wrapper {
    height: 100%;
    gap: 44px;
    display: flex;
    flex-direction: column;
}

.slides3-images {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100%;
}