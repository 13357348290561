// .cube {
//   position: absolute;
//   top: -50px;
//   width: 50px;
//   height: 50px;
//   animation: fall linear forwards, fade-out 1s 10s forwards;
// }

// @keyframes fall {
//   0% {
//     transform: translateY(-100%);
//     opacity: 1;
//   }
//   80% {
//     opacity: 1;
//   }
//   100% {
//     transform: translateY(calc(100vh + 50px));
//     opacity: 0;
//   }
// }

// @keyframes fade-out {
//   0% {
//     opacity: 1;
//   }
//   100% {
//     opacity: 0;
//   }
// }


.cube {
  position: absolute;
  top: -50px;
  width: 50px;
  height: 50px;
  animation: fall linear forwards, fade-out 1s 10s forwards;
}
