.explore-btn {
  height: 40px;
  width: calc(100% - 12px) !important;
  color: #9747FF;
  font-size: 20px;
}

.connected-address {
  justify-content: center;
  display: flex;
  gap: 8px;
}

.wallet-connect-btn-paypass-wrapper {
  height: 57px;
}

.wallet-connect-btn-paypass {
  width: 327.84px;
  height: unset;
  padding: 0;
  border-radius: unset;
  background-color: transparent;
  border: unset;
  font-family: "Archivo Black", sans-serif !important;
  font-style: normal !important;
  font-weight: 400 !important;;

  & button {
    background-color: transparent!important;
    font-family: "Archivo Black", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: inherit;
    font-size: 19px;
    z-index: 1;
    width: 155px;
    height: 57px;
  }

  & .go3974562317 {
    font-family: "Archivo Black", sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;;

    & .go1487791704 {
      font-family: "Archivo Black", sans-serif !important;
      font-style: normal !important;
      font-weight: 400 !important;
    }
  }
}

.wallet-connect-btn-paypass-wrapper {
  width: 327.84px!important;
}

.wallet-connect-btn-paypass button div {
  font-size: 26px;

}

.paPassAddress {
  font-size: 14px;
}

.payPassBorder {
  background: transparent;
  box-shadow: unset;
  border: unset;
  padding: 0;
  max-width: 0 auto;
}

.wallet-connect-btn-stars {
  width: 163px;

  .go1487791704 {
    visibility: hidden;
  }
}

.wallet-connect-btn-stars {
    font-size: 19px;
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
    height: 57px;
}

.wallet-connect-btn-paypass-wrapper-start-connect {
  & .go1487791704 {
    font-size: 19px;
    display: none;

  }

  & .go3448938692 {
    min-width: auto;
  }

 #ton-connect-button > div {
    width: 0px;
    /* background-color: red;
    width: 155px;
    height: 57px;
    border-radius: 36px; */
  }

  .go1651228014 {
    display: none;
  }
}

.wallet-connect-btn-paypass-wrapper-start {
  min-width: 155px;
  width: unset!important;
  text-align: center;
} 

.wallet-actions-wrapper-start-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 4px;
}

.wallet-actions-wrapper-start-wrapper-actions {
 display: flex;
 gap: 4px;
 justify-content: space-between;
}

.wallet-connect-btn-paypass {
  z-index: 1;
}

.pay-pass-light {
  color: #5096FF !important;
  border: 3px solid #5096FF !important;
  box-shadow: 0px 0px 10px #5096FF, 0px 0px 30px #5096FF !important;
  background-color: #fff;

}