.upgrade-type-statistics-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.upgrade-type-statistics-root {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
}

.upgrade-type-statistics-item {
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
}

.upgrade-type-statistics-level {
    display: flex;
    flex-direction: row;
    gap: 8px;
    width: 84px;
    justify-content: flex-end;
}

.upgrade-type-statistics-price {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
}