.wai-main-logo {
    margin-top: 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 20;
}

.confetti {
    z-index: 10000003!important;
}