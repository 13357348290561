.tonPriceColor {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(to right, #2D83EC, #1AC9FF);

}

.starsPriceColor {
    color: transparent;
    -webkit-background-clip: text;
    background-clip: text;
    background-image: linear-gradient(to right, #F7CE45, #ECECEC);
}