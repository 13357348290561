.wallet-content-wrapper {
    width: 100%;
    border-radius: 27px;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 8px;
    overflow: hidden;
    justify-content: flex-start;
};

.wallet-content-balance {
    display: flex;
    flex-direction: column;
    gap: 8px;
    align-items: center;
}

.page-wallet-btn {
    background-color: transparent!important;
    border: 3px solid #9747FF!important;
    border-radius: 19px;
    color: #9747FF!important;
    margin: 0!important;
    max-width: unset!important;
    display: flex;
    flex-direction: column;
    gap: 4px;
    color: #A159FF;
    border: 3px solid #A159FF;
    background: linear-gradient(3deg, rgba(130, 84, 189, 0), rgba(35, 35, 231, 0.011) 134%);
    padding: 17px 35px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 36px;
    font-size: 26px;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.5s ease-in forwards;
    box-shadow: 0px 0px 10px #8254BD, 0px 0px 22px #C333E7;
    width: 100% !important;
    margin: 24px!important;

}

.c-rune-icon {
    width: 60px;
    position: absolute;
    right: 50px;
    z-index: 1;
}

.wallet-wrapper {
    padding: 12px;
}

.content-wrapper-title {
    font-size: 24px;
    color: #E7D5FF; 
    padding-left: 40px;
}

.content-wrapper-root {
    display: flex;
    gap: 8px;
    flex-direction: column;
}
.content-wrapper-body {
    border: 2px solid #8C2AD9;
    border-radius: 31px;
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 40px;
}

.wallet-content {
    gap: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.wallet-roadmap-wrapper {
    display: flex;
    flex-direction: column;
    gap: 6px;
}

.wallet-roadmap-item {
    display: flex;
    gap: 8px;
    align-items: center;
}

.wallet-roadmap-text {
    font-size: 16px;
    font-weight: 500;
}

.wallet-balance {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 40px;
}

.wallet-balance-item-wrapper{
    display: flex;
    flex-direction: column;
    align-items: baseline;
    gap: 4px;
}

.wallet-balance-item {
    display: flex;
    flex-direction: row;
    gap: 8px;
    align-items: baseline;
}

.wallet-balance-item-ton-balance {
    font-size: 18px;
    color: #B5B5B5;
}

.wallet-balance-item-text {
    display: flex;
    gap: 4px;
    align-items: center;
}

.wallet-balance-main-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}