.success-pay-pass-modal {
  padding: 16px;
}
.success-pay-pass-modal-content {
  display: flex;
  flex-direction: column;
  gap: 50px;
  align-items: center;
}

.success-pay-pass-modal-content-reward {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}