.page-leaderboard {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
    height: 100vh;
}

.leaderboard-header {
  margin-top: 40px;
}

.leaderboard-content-wrapper {
    width: 100%;
    background-color: #fff;
    border-radius: 15px;
    padding: 30px;
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 14px;
};

.leaderboard-heading {
    display: flex;
    align-items: center;
}

.leaderboard-heading {
    width: 100%;
    display: flex;
    gap: 4px;
    justify-content: space-between;
    font-size: 28px;
}


@import '@radix-ui/colors/black-alpha.css';
@import '@radix-ui/colors/mauve.css';
@import '@radix-ui/colors/violet.css';

/* reset */
button {
  all: unset;
}

.ToggleGroup {
  display: inline-flex;
  border-radius: 4px;
  width: 100%;
  max-width: 340px;
  border-radius: 15px;
  border: 1px solid #E3CEFF;
  padding: 4px;
}

.leaderboard-toggler-wrapper {
    display: flex;
    justify-content: center;
    width: 95%;
}

.ToggleGroupItem {
  background-color: transparent;
  color: #DFF2D8;
  height: 35px;
  width: 100%;
  display: flex;
  font-size: 15px;
  line-height: 1;
  align-items: center;
  justify-content: center;
  margin-left: 1px;
  border-radius: 10px;
}

.ToggleGroupItem[data-state=on] {
  background-color: #9747FF!important;
  color: #DFF2D8!important;
}

.ToggleGroupItem:last-child {
//   border-radius: 16px;

}
.ToggleGroupItem:hover {
  background-color: var(--violet-3);
}
.ToggleGroupItem[data-state='on'] {
  background-color: var(--violet-5);
  color: var(--violet-11);
}
.ToggleGroupItem:focus {
  position: relative;
//   box-shadow: 0 0 0 2px black;
}

// 
.leaderboard-item-score {
  width: 20px;
}

.leaderboard-item {
  padding: 28px;
  width: 100%;
  border-radius: 18px;
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  height: 86px;
} 

.leaderboard-item-balance {
  font-size: 16px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 4px;
}

.leaderboard-item-me-bg {
  background: linear-gradient(to right, #9747FF 0%, #0E0E0E 100%);
}

.leaderboard-item-other-bg {
  background-color: #D9D9D9;
}

.leaderboard-item-name {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  
}


.leaderboard-item-name,
.leaderboard-item-balance {
  font-size: 16px;
  font-weight: 500;
}

.leaderboard-item-balance {
  gap: 4px;
  color: #565656;
}

.leaderboard-item-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  overflow: hidden;
  height: 30px;
}

.leaderboard-item-place {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
}

.leaderboard-item-medal {
  font-size: 22px;
  margin-left: -4px;
}

.leaderboard-content-overflow {
  overflow: auto;
}

.leaderboard-content-list {
  -ms-overflow-style: none;  /* IE и Edge */
  scrollbar-width: none;  /* Firefox */
  overflow: auto;
  width: 100%;
  gap: 8px;
  display: flex;
  flex-direction: column;

  &::-webkit-scrollbar {
    display: none;
  }
}

