.statistics-item {
  width: 100%;
    display: flex;
    flex-direction: column;

    .page-claim-title {
        color: #DFF2D8 !important;
    }
}

.statistics-item-value-label {
    font-size: 50px !important;
} 

.statistics-wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.stats-amount {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
      .page-claim-title {
        color: #DFF2D8 !important;
      }
}

.stats-value {
    font-size: 30px !important;
}



.onlineImg {
    animation: blink 1s linear infinite;
  }

  @keyframes blink{
    0% {opacity: 0.3;}
    50%{opacity: 1;}
    100%{opacity: 0.3;}
  }


  .statItemIcon {
    width: 30px;
  }