.collab {
    &-root {
        height: 100%;
        overflow: hidden;
        display: flex;
        flex-direction: column;
        background-color: rgba(217, 217, 217, 0.3) !important;
        border-radius: 20px;
        padding: 16px;
        gap: 12px;
    }

    &-task-icon {
        width: 70px;
        height: 70px;
    }

    &-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
        overflow: auto;
        height: 100%;
    }

    &-list-item {
        display: flex;
        flex-direction: row;
        gap: 18px;
    }

    &-item-desc {
        display: flex;
        flex-direction: row;
        gap: 4px;
    }

    &-item-content {
        display: flex;
        align-items: center;
    }

    &-footer {
        display: flex;
        flex-direction: column;
        width: 100%;
        align-items: center;
        justify-content: center;
        color: #fff;

        &-counter {
            font-size: 18px;
        }

        &-divider {
            font-size: 24px;
        }

        &-logo {
            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 8px;
        }
    }

    &-done {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 24px;
        height: 100%;
    
        
        &-desc {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 4px;
            color: #fff;
        }
    }
}
