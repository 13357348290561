.logo-container {
    position: absolute;
    top: 0;
    left: 0;
  }
  
  .logo {
    z-index: 1000000;
    position: absolute;
    width: 25.6px;
    height: auto;
    opacity: 1;
    transition: transform 0.5s ease-out, opacity 0.5s ease-out;
    user-select: none;
    pointer-events: none;
  }