/* AccordionComponent.css */
.accordion-root {
  width: 100%;
  overflow-y: auto;
  height: 100%;
  padding: 5px 0;
}

.accordion-button {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  padding: 1em;
  background-color: #f5f5f5; /* Set default background color */
  border: none;
  outline: none;
  width: 100%;
  text-align: left;
  font-size: 1rem;
  transition: background-color 0.3s ease, transform 0.3s ease;
  border: 1px solid #fff;
  color: #fff; /* Changed to black for better contrast */
  background-color: transparent;
}

.accordion-icon {
  transition: transform 0.3s ease;
}

/* Rotate the icon when the accordion is expanded */
.accordion-button[aria-expanded="true"] .accordion-icon {
  transform: rotate(90deg);
}

.accordion-first-section {
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}

.accordion-last-section {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.accordion-last-section-light {
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}

.accordion__item {
  margin-top: -2px;
}

.accordion__panel {
  background-color: rgba(36, 36, 36, 0.86);
  color: #fff;
}

.accordion-desc-item {
  display: flex;
  align-items: center;
  gap: 4px;

  & img {
    width: 30px;
  }
}
