.free-boosters-root {
    display: flex;
    flex-direction: column;
    gap: 12px;
}


.free-boosters-root h1 {
    font-size: 22px;
    font-weight: 700;
}

.free-boosters-items {
    display: flex;
    flex-direction: row;
    gap: 12px;
}

.free-boosters-item {
    width: 50%;
    background-color: rgba(217, 217, 217, 0.5);
    border-radius: 16px;
    padding: 8px;
    display: flex;
    flex-direction: row;
    gap: 8px;
    justify-content: flex-start;
    align-items: center;
}

.free-boosters-item-desc {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 8px;
}

.free-boosters-item-desc p {
    font-size: 14px;
    font-weight: 500;
}   

.free-boosters-item-icon {
    font-size: 30px;
}

.free-boosters-item-available {
    font-size: 13px;
    color: #6E6E6E;
    font-weight: 500;
}

.booster-disabled {
    opacity: 1!important;
}