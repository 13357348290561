.pay-pass-root {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 30px;
  margin-bottom: 18px;
  gap: 20px;
}

.pay-pass-icons {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 30%;
  width: 100%;
  align-items: center;
}

.pay-pass-logo {
  height: 30%;
}