.boosters-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 24px;
}

.boosters-heading {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 24px;
    height: 50%;
    flex: 0;
}

.boosters-heading .box {
    gap: 8px;
}

.boosters-heading .box {
    display: flex;
    flex-direction: column;
    align-items: center;
    // height: 100%;
}

.task-booster {
    justify-content: space-between;
}
