.carousel {
    position: relative;
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    overflow: hidden;
    height: 100%;
  }

  .carousel-template-page {
    width: 100%;
    height: 100%;
    gap: 20px;
    overflow: auto;
  }
  
  .carousel-slide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    height: 100%;
    transition: transform 0.5s ease-in-out;
  }
  
  .carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: unset !important;
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    z-index: 1;
  }
  
  .carousel-button.prev {
    left: 0;
    border: unset !important;
    margin-left: 8px;
    border-radius: 16px;
    padding: 12px;
    padding: 14px 20px;
  }
  
  .carousel-button.next {
    right: 0;
    border: unset !important;
    margin-right: 8px;
    border-radius: 16px;
    padding: 12px;
    padding: 14px 20px;
  }
  
  .carousel-item {
    width: 100%;
    height: 100%;
  }

  .carousel-button-next-paddings {
    padding: 9px 8px!important;
  }