.page-chest-onboarding-slide2-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 50px 26px 20px;
  overflow: auto;
}

.page-chest-onboarding-slide2-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  &-hight {
    font-weight: bold; 
    font-weight: 100;
  }

  &-hight,
  &-low  {
    font-size: clamp(24px, 16vw, 61px); 
    color: #fff;
    text-align: center;
    line-height: .7;
  }

  &-low {
    color: #C333E7;
  }
}

.page-chest-onboarding-slide2-body {
  padding: 14px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.page-chest-onboarding-slide2-body {
  display: flex;
  flex-direction: column;

  &-item {
    display: flex;
    align-items: center;
    gap: 8px;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;

    &-img {
      width: 125px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-text {
      font-size: 16px;
      color: #fff;
    }
  }
}

.page-chest-onboarding-slide2-body-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
  justify-content: center;

  &-item {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 4px;

    &-text {
      color: transparent;
      -webkit-background-clip: text;
      background-clip: text;
    }
  }


}
