.app-modal-root {
  align-items: end;
}

.app-modal-content-wrapper {
  position: relative;
    display: flex;
    width: 100%;
    border-radius: 27px 27px 0 0;
    background-color: rgba(0, 0, 0, 0.8);

    padding: 70px 15px 25px;
    flex-direction: column;
    justify-content: flex-end;
    gap: 24px;
    align-items: center;
    align-self: end;
}

.app-modal-content-button {
    max-width: 200px;
    width: 100%;
    padding: 16px;
    font-size: 17px;
    color: #fff;
    border-radius: 22px;
    border: 1px solid transparent;
    background-color: #9747FF;
    margin: 0 auto;
    text-align: center;
    max-width: 90%;
}

.app-modal-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    align-items: center;
}

.app-modal-content-label {
    font-size: 20px;
    color: #fff;
}

.app-modal-content-description {
    display: flex;
    justify-content: center;
    max-width: 210px;
}

.app-modal-content-description p {
    display: flex;
    gap: 4px;
    font-size: 14px;
    color: #CDCDCD;
    align-items: center;
    text-align: center;
}

.app-modal-content-bonus {
    font-size: 26px;
    color: #fff;
}

.app-modal-content-level {
    color: #CDCDCD;
    font-size: 14px;
}

.app-modal-content-bonus-wrapper {
    display: flex;
    flex-direction: row;
    gap: 4px;
    align-items: center;
}


.fade-enter {
    opacity: 0.01;
    transform: translateY(100%);
  }
  
  .fade-enter.fade-enter-active {
    opacity: 1;
    transform: translateY(0%);
    transition: opacity 400ms ease-in, transform 400ms ease-in;
  }
  
  .fade-exit {
    opacity: 1;
    transform: translateY(0%);
  }
  
  .fade-exit.fade-exit-active {
    opacity: 0.01;
    transform: translateY(100%);
    transition: opacity 400ms ease-in, transform 400ms ease-in;
  }

  .is-max-level {
    justify-content: center;
  }

  .task-modal-wrapper {
    display: flex;
    width: 100%;
    height: 85%;
    justify-content: center;
    align-items: unset;
    flex-direction: row;
    position: relative;
  }

  .task-modal-button-wrapper {
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }

  .task-modal-content {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }

.task-modal-desc {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.task-modal-icon {
  background-color: #fff;
  border-radius: 16px;
  padding: 8px;
}

.task-modal-icon img {
    width: 90px;
    height: 90px;
}

.close-modal {
  position: absolute;
  top: 20px;
  right: 20px;
  color: #fff;
  font-size: 30px;
  font-weight: 300;
}

.app-modal-content-icon {
  font-size: 100px;
}