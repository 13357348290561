.wai-balance-root {
    display: flex;
    gap: 24px;
    justify-content: space-between;
}

.wai-balance-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;
}

.wai-balance-text {
    font-size: 16px;
}

.wai-balance {
    font-size: 6vw!important;
}


.experts-color {
   color: #BCCAFF !important;
}

.wai-color {
    color: #A344D2 !important;
}

.wai-invite-mate {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px; 
    color: #BCCAFF;
    margin-bottom: 4px;
    margin-left: 8px;
}

.addMatesImg {
    animation: blink 1s linear infinite;
  }
  
  @keyframes blink{
    0% {opacity: 0.3;}
    50%{opacity: 1;}
    100%{opacity: 0.3;}
  }
  
