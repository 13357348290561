.blockchain-wrapper {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    gap: 25px;
    overflow-y: scroll;
}

.blockchain-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-wrapper {
        border-radius: 27px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 72px;
    }

    &-name {
        font-size: 42px;
        display: flex;
        align-items: center;
        justify-content: center;
        align-items: center;
        color: #fff;

    }

    &-select-wrapper {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &-select-wrapper {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &-main {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 52px;
        }
    }

    &-select {
        border-radius: 16px;
        font-size: 18px!important;

        &-label {
            font-size: 18px;
            font-weight: 500;
        }

    }

    &-select .css-13cymwt-control {
        border-radius: 16px!important;
        background-color: rgba(217, 217, 217, 0.5);
    }

    &-select .css-t3ipsp-control {
        border-color: transparent!important;
        box-shadow: 1px 1px 1px 1px hsl(0, 0%, 80%) !important;
        outline:none!important;
        border: 1px solid red;
        border-color: hsl(0, 0%, 80%);
        border-radius: 16px!important;
        background-color: rgba(217, 217, 217, 0.5)!important;
    }

    &-select .css-1jqq78o-placeholder,
    &-select .css-qbdosj-Input input {
        font-size: 18px;
        color: #000;
    }

    &-select .css-1fdsijx-ValueContainer {
        padding: 16px;
    }
    

    &-notification {
        border-radius: 18px;
        background-color: rgba(217, 217, 217, 0.5);
        padding: 28px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        margin: 0 auto;
        height: 23vh;
        justify-content: center;
        gap: 24px;

        &-label {
            width: 100%;
            display: flex;
            font-size: 19px;
            gap: 4px;
            display: flex;
            justify-content: center;
        }

        &-text {
            font-size: 16px;
            font-weight: 400;
            text-align: center;
        }
    }
}

.page-blockchain-btn {
    background-color: #fff !important;
    border: 3px solid #9747FF!important;
    border-radius: 19px;
    color: #9747FF !important;
    margin: 0 !important;
    // max-width: unset !important;
    width: unset;
}

.button-wrapper {
    width: -webkit-fill-available;
    display: inline;
    border: 0px solid transparent!important;
}

.button-wrapper b {
    color: #000;
}

.support-wrapper {
    display: flex;
    gap: 8px;
    justify-content: center;
}

.blockchain-ton-text {
    color: #E7D5FF;
    font-size: 16px;
}

.blockchain-ton-text-first {
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 20px;
}

.blockchain-ton-text-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.blockchain-rights {
    font-size: 12px;
    color: #fff;
    font-weight: 600;
}

.blockchain-content-footer {
    gap: 12px;
}

.toggler-text {
    color: #fff;
    font-size: 16px;
}

.toggler-wrapper {
    display: flex;
    gap: 8px;
    align-items: center;
}