.page, .page-1 {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100vh;
    max-height: 100vh;
    background-position: center;
    background-size: cover;
}

  .mates-invite-root {
    background-color: rgba(238, 238, 238, 0.2);
    border-radius: 16px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }


  .page-claim {
  padding: 0;
}

.page-1 .main-button {
    width: 100%;
    color: #ffffff;
    background-color: #464C72;
    font-size: 20px;
    height: auto;
    padding: 15px 0;
    border-radius: 36px;
}

.mates-tg-link {
    display: flex;
    justify-content: center;
    text-decoration: none;
}

.mates-description {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.mates-content {
  gap: 16px;
  justify-content: space-between;
}

.mates-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 32px;
  overflow: hidden;
}

.mates-list-item-short-username {
  font-size: 34px;
}

.mates-level {
  display: flex;
  flex-direction: row;
}

.mates-avatar, .mates-avatar-left-side {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.mates-level-p {
  font-size: 18px;
  font-weight: 400;
  gap: 12px;
}

.mates-avatar-left-side  {
  align-items: center;
}

.mates-invite-p {
  font-size: 14px;
  font-weight: 400;
}

.mates-invite-button {
  background-color: #ffffff;
  color: #9747FF;
  font-size: 16px;
  border-radius: 16px;
  padding: 9px 15px;
  border: 1px solid #fff;
}

.mates-invite-button-wrapper {
  display: flex;
  align-items: center;
}

.mates-progress {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: space-between;
}
  

.mates-progress {
  width: 100%;
}

.mates-content-desc {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 28px;
  justify-content: space-between;
}

.mates-content-desc-level-wrapper {
  padding: 16px;
  border-radius: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 86px;
  height: 86px;
}

.mates-content-desc-level {
  font-size: 40px;
  line-height: 0.8;
  font-weight: 400;
}

.mates-content-desc-level-desc {
  font-size: 14px;
  font-weight: 400; 
  color: #565656;
}

.mates-max-level {
  display: flex;
  justify-content: center;
}

.mates-last-level {
  display: flex;
  justify-content: center;

}

.mates-main-desc {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.mate-button {
  max-width: 200px;
  width: 100% !important;
  // margin: 0 auto !important;
  text-align: center;
  max-width: 100% !important;
}