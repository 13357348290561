.page-chest-onboarding-slide1-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  padding: 40px 26px 20px;
  overflow: auto;
}

.page-chest-onboarding-slide1-header {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 14px;

  &-hight {
    font-weight: bold; 
    font-weight: 100;
  }

  &-hight,
  &-low  {
    font-size: clamp(24px, 9vw, 42px); 
    color: #fff;
    text-align: center;
    line-height: .7;
  }

  &-low {
    color: #C333E7;
  }
}

.page-chest-onboarding-slide1-body {
  padding: 14px;
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
}

.page-chest-onboarding-slide1-body {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &-item {
    display: flex;
    align-items: center;
    gap: 20px;
    flex-direction: row;
    width: 100%;
    justify-content: flex-start;

    &-img{
      height: 111.58px;

    }

    &-img,
    &-img-arrow {
      width: 102.31px;
      display: flex;
      justify-content: center;
      align-items: center;
    }

    &-text {
      font-size: 16px;
      color: #fff;
    }
  }
}