.list-root {
    width: 100%;
    overflow: hidden;
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.list-title {
    display: flex;
    height: 39px;
    font-size: 24px;
    align-items: center;
    font-weight: 400;
}

.list-wrapper {
    height: 100%;
    overflow-y: auto;
}

.list-title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.mates-list-empty {
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
}

.mates-list-empty p {
    font-size: 15px;
    color: #52544F;
}

.total-rewards-wrapper {
    display: flex;
    width: 100%;
    justify-content: flex-end;
    height: 30px;
    overflow-y: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: flex;
    gap: 4px;
    align-items: center;
}

.total-rewards {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

