.pay-pass-tabs-root {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
}

.pay-pass-tabs-left-tab {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}

.pay-pass-tabs-right-tab {
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
}

.pay-pass-tabs-left-tab,
.pay-pass-tabs-right-tab  {
  color: #fff;
  font-size: 16px;
  text-align: center;
  width: 100%;
  height: 28px;
  background-color: rgba(217, 217, 217, 0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.pay-pass-tabs-active-tab {
  background-color: #fff!important;
  color: #9747FF;
}

.pay-pass-purchased {
  border-radius: 10px;
  padding: 5px;
}