.task-link-wrapper {
    display: flex;
    justify-content: flex-start;
    gap: 8px;
    text-decoration: none;
    position: relative;
    align-items: center;
}

.color-white {
    color: #ffffff;
}

.claim-task-box {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(151, 71, 255, 0.7);
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 16px; 

}

.task-item-info-stack {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
}

.task-item-info-stack p  {
    color: #565656;
    font-size: 15px;
    font-weight: 500;
}

.task-item-reward {
    display: flex;
    gap: 4px;
}

.task-item-avatar {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 88px;
    height: 88px;
    border-radius: 16px;
    background-color: #EEEEEE;
    max-width: 99px;
    max-height: 102px;
    position: relative;
}


.task-icon {
    font-size: 34px;
}

.completed {
    pointer-events: none;
}

