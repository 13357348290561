.rune-description {
    display: flex !important;
    flex-direction: column  !important;;
    gap: 24px  !important;;
}

.rune-modal-button-wrapper {
    width: 100%;
    padding: 16px;
    font-size: 17px;
    color: #fff;
    border-radius: 22px;
    border: 1px solid transparent;
    background-color: #9747FF;
    margin: 0 auto;
    text-align: center;
    max-width: 90%;
  }

  .runes {
    display: flex;
    gap: 8px;
  }