.wai-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
    gap: 30px;
}

.wai-modal-divider {
    width: 100%;
    height: 1px;
    background-color: #7A7A7A;
}

.wai-onboarding-modal {
    background-color: unset !important;
    height: 100%;
    overflow: hidden;
    padding-top: 30px!important;
}