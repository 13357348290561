.carousel-template-page {
  height: 100%;
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
}

.carousel-template-page-children {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.carousel-template-page-text {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  & p {
    text-align: center;
  }
}

.carousel-template-page-text-title {
  width: 100%;
  color: #fff;
  font-size: 42px;
  font-weight: 100;
}

.carousel-template-page-text-subtitle {
  color: #A344D2 !important;
  font-size: 42px;
}

.carousel-template-page-text-label {
  color: #A344D2 !important;
  font-size: 34px;
}