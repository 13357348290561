.template-root {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    // background-color: #fff;
    position: relative;
}

.app-spinner {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 200000000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: not-allowed;
}


.overlay {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 10000001;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.fade1-enter {
  opacity: 0.01;
}

.fade1-enter.fade1-enter-active {
  opacity: 1;
  transition: opacity 400ms ease-in, transform 400ms ease-in;
}

.fade1-exit {
  opacity: 1;
}

.fade1-exit.fade1-exit-active {
  opacity: 0.01;
  transition: opacity 400ms ease-in, transform 400ms ease-in;
}

.template-body {
  z-index: 1000;
}