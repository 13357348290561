.wai-upgrade-item {
    filter: blur(2%);
    border-radius: 50%;
    border: 2px solid #8254BD;
    box-shadow: 0px 0px 10px #8254BD, 0px 0px 30px #C333E7;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(3deg, rgba(130, 84, 189, 0.4), rgba(35, 35, 231, 0.011) 134%); 
}

.wai-upgrade-item-img {
    position: absolute;
    height: 60%;
}

.wai-upgrade-item-level {
    position: absolute;
    bottom: -5px;
    left: -5px;
    width: auto;
    height: auto;
    aspect-ratio: 1 / 1;
}

.wai-upgrade-item-level p {
    font-size: 3vw;
}